import { ConnectButton } from '@rainbow-me/rainbowkit'
import bg from './imgs/back.gif'
import fg from './imgs/land.jpg'
import mintGif from './imgs/mint.gif'
import dd from './imgs/DERP_TOKEN.png'
import React from 'react'
import Web3 from 'web3'

import {
	useAccount,
	useChainId,
  usePrepareTransactionRequest,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from 'wagmi'

import { parseEther } from 'viem'
import { config, shop } from './config'

import pendingGif from './imgs/pending2.gif'
import flashing from './imgs/flashing.gif'
import derpeItems from './imgs/derp-items.png'

import discord from './imgs/discord.png'
import tg from './imgs/icon--telegram.png'
import x from './imgs/icon--twitter.png'

import closeImg from './imgs/close.png'

const getTransactionReceipt = (hash, callback= ()=>{}) => {
    console.log(hash)
    window.ethereum.request({
      method: "eth_getTransactionReceipt",
      params: [hash]
    }).then((res)=>{
      if (res == null) {
        getTransactionReceipt(hash, callback)
      }
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.soniclabs.com'));



export function Web3App (props) {
	const [cost, setCost] = React.useState(100)  
	const [pending, setIsPending] = React.useState(false)
	const [pendingText, setPendingText] = React.useState("Transaction is in process") 
	const { address, isConnected } = useAccount()
	const chainId  = useChainId()

	const [networkConfig, setNetworkConfig] = React.useState(config["mainnet"])

	

	const sendRequest = (contract, data, callback = ()=>{}, payable = 0, gasAmount=0) => {
		setIsPending(true)
	

		if (typeof window.ethereum !== 'object') { 
		
		} else {
		

		window.ethereum.request({
			method: "eth_sendTransaction",
			params: [
			  {
			    from: address,
			    to: contract,
			    value: payable,
			    data: data,
			  },
			],}).then((hash)=>{
			  getTransactionReceipt(hash,(receipt)=>{
			    if (receipt != null) {
			      setIsPending(false)
			      callback()
			    }
			  })
			}).catch((err)=>{
			  setIsPending(false)
			})	
		}
		
	}

	const estimateGas = (contract, data, saveError, callback = ()=>{}, payable = 0) => {
	console.log("estimating gas", payable)
	console.log("window ethereum object", window.ethereum)
	console.log("typeof window ethereum",typeof(window.ethereum))

	if (typeof window.ethereum !== 'object') {
		alert("Please open this page in the Metamask browser")
	
	} else {

		window.ethereum.request(
	        {
	          method: "eth_estimateGas",
	          params: [
	            {
	              from: address,
	              to: contract,     
	              value: payable,
	              data: data,
	            },
	          ],
	        }
	      ).then((gasAmount)=>{
	      	console.log(gasAmount)
	        callback(gasAmount)
	      }).catch((err)=>{

	        saveError(err.data.message)
	        alert(err.data.message)
	      })
	      
		}
   
}
	

	const derps = new web3.eth.Contract(networkConfig["derps"]["abi"], networkConfig["derps"]["address"])
	const items = new web3.eth.Contract(networkConfig["derpPromoItems"]["abi"], networkConfig["derpPromoItems"]["address"])
	const derpToken = new web3.eth.Contract(networkConfig["derp"]["abi"], networkConfig["derp"]["address"])
	React.useEffect(()=>{
		

	},[networkConfig])
  

  const [style, setStyle] = React.useState({backgroundImage: `url(${fg})`})

  React.useEffect(
  	() => {
  		if (isConnected && !pending) {
  			setStyle({backgroundImage: `url(${fg})`})
  		} else {
  			if (pending) {
  				setStyle({backgroundImage: `url(${flashing})`})
  			} else {
  				if (isConnected) {
  					setStyle({backgroundImage: `url(${fg})`})
  				} else {
  					setStyle({backgroundImage: `url(${fg})`})
  				}
  				
  			}
  		}
  	},
  	[pending, isConnected]
  	)

  const [bal, setBal] = React.useState(0)
  const getBal = () => {
  	derps.methods.balanceOf(address)
  		.call({from: address})
  		.then((balance)=>{
  			setBal(balance)
  		})
  }

  const ShopItem = (props) => (
  	<div className="shop-item" onClick={props.click}>
  		<div className="shop-item-img">
  			<img src={props.itemImg} />
  		</div>
  		<div className="shop-item-info">
  			<div className="item-id">#{props.itemId}</div>
  			<div className="item-name">{props.itemName}</div>
  			<div className="item-cost">Cost: {props.itemCost} $DERP</div>
  		</div>
  	</div>
  	)

  const itemIds = ["110","111","112","10008","10009","10010"]

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [activeModalID, setActiveModalID] = React.useState(0)
  const openItemModal = (itemID) => {
  	return () => {
  		if (isConnected) {
  			setIsModalOpen(true)
  			setActiveModalID(itemID)
  		} else {
  			alert("Please connect your wallet")
  		}
  		
  	}
  }
  const closeItemModal = () => {
  	setIsModalOpen(false)
  }
  


  React.useEffect(()=>{
  	if (itemIds.indexOf(activeModalID) > -1 && isConnected) {
  		getOnChainItemInfo()
  	}
  },[activeModalID])

const [onChainItemInfo, setOnChainItemInfo] = React.useState(null)
  const getOnChainItemInfo = () =>{
  	items.methods.getItem(activeModalID)
  		.call({from: address})
  		.then((res)=>{
  			console.log(res)
  			setOnChainItemInfo(res)
  		})
  }

const getDerpBal = (callback = ()=>{}) => {
	derpToken.methods.balanceOf(address)
		.call({from: address})
		.then((b)=>{
			callback(b)
		})
}

const getDerpAllowance = (callback = ()=>{}) => {
	derpToken.methods.allowance(address, networkConfig["derpPromoItems"]["address"])
		.call({from: address})
		.then((a)=>{
			callback(a)
		})
}

const approveDerp = (callback = ()=>{}) => {
	let spender = networkConfig["derpPromoItems"]["address"]
	let amount = web3.utils.toWei(onChainItemInfo.derpCost,"ether")
	let data = derpToken.methods.approve(spender,amount).encodeABI()
	setPendingText("Approving $DERP")
	sendRequest(
		networkConfig["derp"]["address"],
		data,
		callback)

}

const mint= (callback = ()=>{}) => {

	let data = items.methods.mintItem(activeModalID).encodeABI()
	setPendingText("Minting the Item")
	sendRequest(
		networkConfig["derpPromoItems"]["address"],
		data,
		callback
		)
}


  const mintItem = () => {

  		// check derp balance of user is greater than or equal to cost
  			// check derp allowance of items contract of user is greater than or equal to cost
  				// mintItem
  			// if not enough allowance
  				// approve cost to be spent by items contract
  					// mintItem
  	items.methods.isLive()
  		.call({from: address})
  		.then((live)=>{
  			if (live) {
				items.methods.checkDerps(address,activeModalID)
				  		.call({from: address})
				  		.then((nextDerp)=>{
				  			console.log(nextDerp)
				  			if (nextDerp != 0 || (nextDerp == 0 && activeModalID > 10000) ) {
				  				getDerpBal((balance)=>{
					  			let b = web3.utils.fromWei(balance, "ether")
					  			if (b >= Number(onChainItemInfo.derpCost)) {
					  				getDerpAllowance((allowance)=>{
					  					let a = web3.utils.fromWei(allowance, "ether")
					  					if (a >= Number(onChainItemInfo.derpCost)) {
					  						mint(()=>{
					  							
					  						})
					  					} else {
					  						approveDerp(()=>{
					  							mint(()=>{
						  						
						  						})
					  						})
					  					}
					  				})
					  			} else {
					  				alert("Balance too low")
					  			}
					  		})
				  			} else {
				  				alert("you have no derps to claim for");
				  			}
				  		})
  			} else {
  				alert("minting is paused")
  			}
  		})
  	

  		

  	
  }

  return (
  	<div className={"App App--" + isConnected + " App-chain--" + chainId}>
	  	<div className="lander">
	  		<img src={fg} />
	  	</div>
	  	<div className="bg bg--color">
	  		<div className="derp-items"><img src={derpeItems}/></div>	
	  		<div className="item-shop">
	  			<div className="item-shop-inner">
	  				{
	  					itemIds.map((id,index)=>(
	  						<ShopItem
	  							click={openItemModal(id)}
	  							itemId={id}
	  							itemImg={shop["event4"][id]["img"]}
	  							itemName={shop["event4"][id]["name"]}
	  							itemCost={shop["event4"][id]["cost"]}
	  						/>
	  						))
	  				}
	  			</div>
	  		</div>


	  		
	  
	  		<div className="dd"><img src={dd}/></div>
	  		<div className="connect-button">
	  			<div className="inliner docs"><a href="https://docs.derpe.xyz" target="_blank">DOCS</a></div>
	  			<div className="inliner"><ConnectButton /></div>
	  		</div>

	  		

	  		<div className={"balance balance--" + bal}>
	  			<div>{bal}</div>
	  		</div>

	  		<div className="links">
     
	          <a href="https://discord.gg/3BUen4F9Tw" target="_blank"><img src={discord} /></a>
	          
	          <a href="https://t.me/derpedewdz" target="_blank"><img src={tg}/></a>
	          
	          <a href="https://x.com/derpedewdz" target="_blank"><img src={x}/></a>
	   
	        </div>

	        <div className={"modal modal--" + isModalOpen}>
	  			<div className="close-button" onClick={closeItemModal}><img src={closeImg} /></div>
	  			<div className="modal-inner mint-box">
	  				<div className="modal-item-img mint-gif">
	  					<img src={(activeModalID > 0) ? shop["event4"][activeModalID]["img"] : ""} />
	  				</div>
	  				<div className="modal-info">
	  					<p>ID #{(onChainItemInfo != null) ? onChainItemInfo.id : ""}</p>
	  					<p>Name: {(onChainItemInfo != null) ? onChainItemInfo.name : ""}</p>
	  					<p>Cost: {(onChainItemInfo != null) ? onChainItemInfo.derpCost : ""} $DERP</p>
	  					<p>Supply: {(onChainItemInfo != null) ? onChainItemInfo.totalSupply : ""}/{(onChainItemInfo != null) ? onChainItemInfo.maxsupply : ""}</p>
	  					<p>Per Derp Limit: {(onChainItemInfo != null) ? onChainItemInfo.perDerpLimit : ""}</p>
	  					<p>Description: {(onChainItemInfo != null) ? onChainItemInfo.description : ""}</p>
	  				</div>
	  				<div className="mint-button"><button onClick={mintItem}>Mint</button></div>
	  			</div>

	  		</div>

	  		<div className={"pending pending--" + pending}>
	  			<p className="pending-title">Transaction Pending</p>
	  			<p className="pending-text">{pendingText}</p>
	  			<div className="pending-gif"><img src={pendingGif} /></div>
	  		</div>
	  	</div>

	  	
	 
  	</div>
  	)
};